import moment from 'moment';

const currentDate = moment().format("YYYY-MM-DD");

export const demoOffers = [
  {
    id: 1,
    type: "standard",
    title: "Pay $4.99 for Salads",
    description: "Western Family, 283g, First 1",
    image: "https://myoffers.saveonfoods.com/mediaCache/ecoupon_4321461.jpg",
    display_banner: "Hot Offer",
    storeList: ["saveonfoods", "pricesmartfoods", "qualityfoods", "nestersmarket", "urbanfare", "buylowfoods"],
    endDate: moment().add(getRandomDayOfWeek(), 'days').format("YYYY-MM-DD"),
    leftMeta: getDaysLeft(currentDate, moment().add(getRandomDayOfWeek(), 'days').format("YYYY-MM-DD")) + " Days Left",
    redeemed: true,
    terms_and_conditions: "Excl. Lotto, tobacco, gift cards, prescriptions, clinics, diabetes care, tickets, charities, wines, bus passes, postage stamps, delivery, insurance, deposit & recycle fees, rewards and taxes, where applicable."
  },
  {
    id: 2,
    type: "standard",
    title: "Earn 4x points",
    description: "Earn three additional More Rewards points on each $1 of qual...",
    image: "https://myoffers.saveonfoods.com/mediaCache/ecoupon_4319135.jpg",
    display_banner: "Bonus Points",
    storeList: ["saveonfoods", "pricesmartfoods"],
    endDate: moment().add(getRandomDayOfWeek(), 'days').format("YYYY-MM-DD"),
    leftMeta: getDaysLeft(currentDate, moment().add(getRandomDayOfWeek(), 'days').format("YYYY-MM-DD")) + " Days Left",
    redeemed: false,
    terms_and_conditions: ""
  },
  {
    id: 6,
    type: "standard",
    title: "Buy 2 Get 1200 Points on Cereal", 
    description: "General Mills, 516g-778g, Limit 2 Promotional Offers",
    image: "https://myoffers.saveonfoods.com/mediaCache/ecoupon_4321494.jpg",
    display_banner: "Hot Offer",
    storeList: ["saveonfoods", "pricesmartfoods", "nestersmarket"],
    endDate: moment().add(getRandomDayOfWeek(), 'days').format("YYYY-MM-DD"),
    leftMeta: getDaysLeft(currentDate, moment().add(getRandomDayOfWeek(), 'days').format("YYYY-MM-DD")) + " Days Left",
    redeemed: false,
    terms_and_conditions: ""
  },
  {
    id: 3,
    type: "standard",
    title: "Pay $4.99 for Cinnamon Buns",
    description: "Iced or Un-Iced, 420g or 480g, First 2",
    image: "https://myoffers.saveonfoods.com/mediaCache/ecoupon_4321467.jpg",
    display_banner: "",
    display_bannerIcon: "",
    display_bannerColor: "",
    storeList: ["saveonfoods", "urbanfare"],
    endDate: moment().add(getRandomDayOfWeek(), 'days').format("YYYY-MM-DD"),
    leftMeta: getDaysLeft(currentDate, moment().add(getRandomDayOfWeek(), 'days').format("YYYY-MM-DD")) + " Days Left",
    redeemed: true,
    terms_and_conditions: ""
  },
  {
    id: 4,
    type: "standard",
    title: "Flash Sale",
    description: "Pay $8.99 for Seven Layer Dip",
    image: "https://myoffers.saveonfoods.com/mediaCache/ecoupon_4321470.jpg",
    display_banner: "Flash Sale",
    storeList: ["saveonfoods", "pricesmartfoods", "buylowfoods"],
    endDate: moment().add(getRandomDayOfWeek(), 'days').format("YYYY-MM-DD"),
    leftMeta: getDaysLeft(currentDate, moment().add(getRandomDayOfWeek(), 'days').format("YYYY-MM-DD")) + " Days Left",
    redeemed: false,
    terms_and_conditions: ""
  }, 
  {
    id: 5,
    type: "standard",
    title: "Pay $10.99 for Mitchell's Toupie Ham",
    description: "Smoked, 1kg or 1.5kg, First 2",
    image: "https://myoffers.saveonfoods.com/mediaCache/ecoupon_4321473.jpg",
    display_banner: "",
    display_bannerIcon: "",
    display_bannerColor: "",
    storeList: ["saveonfoods", "qualityfoods"],
    endDate: moment().add(getRandomDayOfWeek(), 'days').format("YYYY-MM-DD"),
    leftMeta: getDaysLeft(currentDate, moment().add(getRandomDayOfWeek(), 'days').format("YYYY-MM-DD")) + " Days Left",
    redeemed: false,
    terms_and_conditions: ""
  },
]

function getDaysLeft(currentDate, endDate) {
  return moment(endDate).diff(currentDate, 'days');
}

// random number between 1 and 7
function getRandomDayOfWeek() {
  return Math.floor(Math.random() * 7) + 1;
}

function getCookie(name) {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
  return null;
}

function getLocalStorageItem(name) {
  var output = null;
  if (name !== "") {
    return localStorage.getItem(name);
  }
  return output
}

function getAppSetting(url, cookie, lStorage) {
  // get the prid from the url, otherwise cookie, otherwise try local storage alternatively return null
  const urlParams = new URLSearchParams(window.location.search);
  const settingSources = [urlParams.get(url), getCookie(cookie), getLocalStorageItem(lStorage)];
  var setting = null;

  for (let i = 0; i < settingSources.length; i++) {
    if (settingSources[i] && settingSources[i] !== 'null') {
      if (lStorage !== '') {
        localStorage.setItem(lStorage, settingSources[i]);
      }
      setting = settingSources[i];
      break;
    }
  }

  return setting;
}

export const getPRID = function () {
  return getAppSetting('prid', 'PRID', 'prid');
}

export const getBanner = function () {
  return getAppSetting('banner', 'BANNER', '');
}

export const getMobile = function () {
  return getAppSetting('mobile', 'MOBILE', 'mobile');
}

function removeEmpty(obj) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null)
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
  );
}

export const getQueryString = function (authenticationQueryObj) {
  const filteredQueryObject = removeEmpty(authenticationQueryObj);

  if (Object.keys(filteredQueryObject).length === 0) {
      return "";
  }

  return "?" + (new URLSearchParams(filteredQueryObject).toString());
}

export const addDataLayerEvent = function (event, payload) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event,
    ...payload
  });
}

export const transposeOffers = function (data) {
  const dedupedoffers = data.filter((offer, index, self) =>
    index === self.findIndex((t) => (
      t.ee_campaign_id === offer.ee_campaign_id
    ))
  );

  return dedupedoffers.map(offer => {

    const endDate = moment(offer.end_date).format("YYYY-MM-DD");
    // console.log(endDate)

    let display_banner = offer.display_banner;
    // let storeList = ['saveonfoods', 'pricesmartfoods', 'qualityfoods', 'nestersmarket', 'urbanfare', 'buylowfoods'];
    // if ('Save-On-Foods Only' === offer.display_banner) {
    //   storeList = ['saveonfoods'];
    //   display_banner = '';
    // }

    let leftMeta = getDaysLeft(currentDate, endDate);

    if (leftMeta === 0) {
      leftMeta = "Ends Today!";
    } else if (leftMeta === 1) {
      leftMeta += " Day Left";
    } else {
      leftMeta += " Days Left";
    }

    let continuityValue = 0;
    let continuityTarget = 0;
    let continuityTotalSpend = 0;
    let continuityPercent = 0;
    let currencyFormat = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' });

    if (offer.type === "CONTINUITY") {
      continuityValue = currencyFormat.format(offer.progress / 100).replace('$', '');
      continuityTarget = offer.target / 100;
      continuityTotalSpend = offer.total_spend / 100;
      continuityPercent = Math.round((continuityValue / continuityTarget) * 100);
    }

    let priority = 0
    // set priority based on display_priority, PRIORITY = 1, STANDARD = 2, LOW = 3
    switch (offer.display_priority) {
      case "Priority":
        priority = 1
      break;
      case "Standard":
        priority = 2
      break;
      case "Low":
        priority = 3
      break;
      default:
        priority = -1
      break;
    }

    return {
      id: offer.ee_campaign_id,
      type: offer.type,
      title: offer.title,
      description: offer.description,
      image: offer.image_link,
      display_banner: display_banner,
      priority: priority,
      storeList: offer.partner,
      leftMeta: leftMeta,
      endDate: endDate,
      eligibleProducts: offer.eligible_products,
      state: offer.state,
      redeemed: offer.state === "USED",
      continuityValue: continuityValue,
      continuityTarget: continuityTarget,
      continuityTotalSpend: continuityTotalSpend,
      continuityPercent: continuityPercent,
      terms_and_conditions: offer.terms_and_conditions
    }
  });
}

export const offerDataExpiredOrEmpty = function () {
  return true
  // localStorage.getItem("offers");
  // const offerData = JSON.parse(localStorage.getItem("offers"));

  // if (!offerData || offerData.prid !== localStorage.getItem("prid")) {
  //   return true;
  // }

  // const timestamp = moment(offerData.timestamp);
  // const now = moment();
  // const diff = now.diff(timestamp, 'minutes');

  // return diff > 30;
}

export const getDisplayBannerProperties = function(display_banner) {
  // let bannerProperties = {
  //   bannerIcon: "",
  //   bannerColor: ""
  // };
  // switch(display_banner) {
  //   case "BLF TM Exclusive Offer":
  //   case "Hot Offer":
  //   case "QF employee offer":
  //     bannerProperties = {bannerIcon: "fire", bannerColor: " red"};
  //   break;
  //   case "Flash Sale":
  //     bannerProperties = {bannerIcon: "lightning", bannerColor: " yellow"};
  //   break;
  //   case "My Mega Deal":
  //     bannerProperties = {bannerIcon: "star", bannerColor: " dark-blue"};
  //   break;
  //   case "Bonus Points":
  //     bannerProperties = {bannerIcon: "trophy", bannerColor: " light-blue"};
  //   break;
  //   case "Save-On-Foods Only":
  //   case "Score N Win":
  //     bannerProperties = {bannerIcon: "cart", bannerColor: " green"};
  //   break;
  //   default:
  //     bannerProperties = {bannerIcon: "", bannerColor: ""};
  //   break;
  // }

  // lowercase, replace spaces with underscores, replace non-alpha characters with empty string
  const cleanBanner = display_banner.toLowerCase().replace(/[^a-zA-Z ]/g, '').replace(/\s/g, '_');
  let bannerProperties = {bannerIcon: "", bannerColor: " " + cleanBanner};

  return bannerProperties;
}